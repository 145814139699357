import "./step3.scss";

import React, { useEffect, useState } from "react";

import { SelectboxComponent } from "../../../../components/select-box/select-box-component";
import { CheckboxComponent } from "../../../../components/checkbox/checkbox-component";
import { TextboxComponent } from "../../../../components/textbox/textbox-component";

export function Step3(props) {
  const [insurancedata, setInsuranceData] = useState({
    memberID: "",
    groupID: "",
    selfPay: false,
  });

  let [insuranceSelected, setInsuranceSelected] = useState(false);

  const rawInsuranceOptions = [
    "Select Health Insurance",
    "Cigna",
    "United Healthcare",
    "Blue Cross Blue Shield",
    "Humana",
  ];
  const insuranceOptions = rawInsuranceOptions.map((option) => {
    return { value: option, label: option };
  });

  const handleSelectChange = (value) => {
    setInsuranceSelected(value !== "Select Health Insurance");
    props.onChange({ provider: value });
  };

  const handleTextChange = (field, e) => {
    props.onChange({ [field]: e.target.value });
    setInsuranceData({ ...insurancedata, [field]: e.target.value });
  };

  const handleCheckboxChange = (value) => {
    setInsuranceData({ ...insurancedata, selfPay: value });
  };

  useEffect(() => {
    props?.setHasValue((value) => ({
      ...value,
      3: insuranceSelected
        ? !!(insurancedata.groupID && insurancedata.memberID)
        : insurancedata.selfPay,
    }));
  }, [insurancedata, props?.setHasValue, insuranceSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h2>What health insurance do you have?</h2>
      <p className="lead">We work with most major insurance companies.</p>
      <div id="step3" className="content sm">
        <SelectboxComponent
          options={insuranceOptions}
          onChange={handleSelectChange}
        ></SelectboxComponent>
        {insuranceSelected && (
          <div className="form-row">
            <TextboxComponent
              placeholder="Group ID"
              onChange={handleTextChange.bind(this, "groupID")}
              type="number"
            />
            <TextboxComponent
              placeholder="Member ID"
              onChange={handleTextChange.bind(this, "memberID")}
            />
          </div>
        )}
        <p className="my-10">OR</p>
        <CheckboxComponent
          caption="I'll pay out of pocket"
          onChange={handleCheckboxChange}
          checked={insurancedata.selfPay}
        />
      </div>
    </>
  );
}
