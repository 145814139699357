import "./step12.scss";

import React from "react";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import { TherapistImageNameCreds } from "../../../../components/therapist/therapist-component";
import { EmailIcon, ProfileIcon, RescheduleIcon } from "../../../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function Step12(props) {
  const dateDisplay = dayjs
    .utc(props.apptInfo.utcDate)
    .local()
    .format("ddd, MMM Do");
  const timeDisplay = dayjs.utc(props.apptInfo.utcDate).local().format("h:mma");

  const calLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=Appointment+with+${
    props.apptInfo.providerName
  }&dates=${dayjs(props.apptInfo.utcDate).format("YYYYMMDDTHHmmss")}/${dayjs(
    props.apptInfo.utcDate
  )
    .add(1, "hour")
    .format("YYYYMMDDTHHmmss")}&details=This+is+an+appointment+with+${
    props.apptInfo.providerName
  }&location=Telehealth&sf=true&output=xml`;

  const handleCalButtonClick = () => {
    window.open(calLink);
  };

  return (
    <>
      <h2>You're on your way to a healthier you!</h2>
      <p className="lead">We look forward to seeing you...</p>
      <div id="step12" className="content lg">
        <div className="dark-block split">
          <div className="split-container">
            <h6 className="text-center">Uppcoming Appointment</h6>
            <div className="light-block">
              <h4>{dateDisplay}</h4>
              <p className="my-2">at {timeDisplay}</p>
              <button
                className="btn btn-primary-outline mx-auto"
                onClick={handleCalButtonClick}
              >
                Add to my Google Calendar
              </button>
            </div>
          </div>
          <div className="split-container">
            <h6 className="text-center">Your Dietitian</h6>
            <TherapistImageNameCreds therapist={props.apptInfo} />
            <div className="bio-links">
              <button className="btn btn-text icon-left">
                <span className="icon">
                  <EmailIcon />
                </span>
                <span className="text">Email</span>
              </button>
              <button className="btn btn-text icon-left">
                <span className="icon">
                  <ProfileIcon />
                </span>
                <span className="text">View Profile</span>
              </button>
              <button className="btn btn-text icon-left">
                <span className="icon">
                  <RescheduleIcon />
                </span>
                <span className="text">Reschedule</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="text-light w-75 mt-10 m-auto">
        Click the button below to log in to your Astra Health account and
        complete your intake forms. We’ve also sent you an email with a link to
        sign in at a later time.
      </p>
    </>
  );
}
