import "../../global.scss";
import './profile.scss';

import React from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from "dayjs/plugin/utc";

import { AstraIcon, NewChevronLeft } from '../../img/icons';


dayjs.extend(advancedFormat);
dayjs.extend(utc);


export function BillingInfo() {

	const navigate = useNavigate();

	return (
		<div className="profile-form">
			<div className="astra-icon">
				<AstraIcon />
			</div>
			<div className="dialog-content">
				<div className="profile-back-button" onClick={ () => navigate('/profile') }>
					<NewChevronLeft />
				</div>
				<div className="dialog-label">
					Billing Info
				</div>
				<div className="edit-button" >
					
				</div>
			</div>
		</div>
	);
}

