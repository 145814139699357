
import './personal-info.scss';

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from "dayjs/plugin/utc";

import OnboardingService, { OnboardingDataCallbackType } from "../../services/onboarding/onboarding-service";
import { LabelTextField } from '../../components/label-text-combo-field/label-text-combo-field';

import { AstraIcon, NewChevronLeft } from '../../img/icons';


dayjs.extend(advancedFormat);
dayjs.extend(utc);


export function PersonalInfo(props) {

	const [ editMode, setEditMode ] = useState(false);
	const [ onboardingService, setOnboardingService ] = useState(null);

	const navigate = useNavigate();
	const [ userData, setUserData ] = useState({
		givenName: ''
		, familyName: ''
		, dateOfBirth: ''
		, lineOne: ''
		, lineTwo: ''
		, city: ''
		, state: ''
		, postalCode: ''
	});

	useEffect(() => {
		const tOnboardingService = new OnboardingService();
		setOnboardingService(tOnboardingService);
	
		tOnboardingService.registerCallback(() => {
			const userDoc = tOnboardingService.getUserDoc();
			const newUserData = { ...userData };
			Object.keys(userData).forEach((key) => {
				newUserData[key] = userDoc[key] ?? '';
			});
			setUserData(newUserData);
		}, OnboardingDataCallbackType.USER_DOC);
	}, [ navigate ]);	// eslint-disable-line react-hooks/exhaustive-deps

	const handleFieldChange = (fieldId, value) => {
		const newUserData = { ...userData };
		newUserData[fieldId] = value;

		setUserData(newUserData);
	}

	const getFieldValue = (fieldId, fieldFormat) => {
		let fieldValue = userData[fieldId];
		if (fieldFormat === "date") {
			const dayObj = dayjs(fieldValue);
			if (dayObj.isValid()) {
				fieldValue = dayjs(fieldValue).format("MM-DD-YYYY");
			}
		}

		return fieldValue;
	}

	const toggleEditMode = async () => {
		const newEditMode = (! editMode);
		setEditMode(newEditMode);
		if (! newEditMode) {
			onboardingService.saveStepData(userData);
			await onboardingService.saveActiveDocument();
		}
	}

	return (
		<div className="profile-form">
			<div className="astra-icon">
				<AstraIcon />
			</div>
			<div className="dialog-content">
				<div className="profile-back-button" onClick={ () => navigate('/profile') }>
					<NewChevronLeft />
				</div>
				<div className="dialog-label">
					Personal Info
				</div>
				<div className="edit-button" onClick={ toggleEditMode }>
					{ editMode ? 'Done' : 'Edit' }
				</div>
			</div>
			<div className="first-name edit-field">
				<LabelTextField isediting={ editMode } label="First Name" fieldid="givenName" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="last-name edit-field">
				<LabelTextField isediting={ editMode } label="Last Name" fieldid="familyName" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="dob edit-field">
				<LabelTextField isediting={ editMode } label="Date of Birth" fieldid="dateOfBirth" format={ "date" } onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="address-1 edit-field">
				<LabelTextField isediting={ editMode } label="Address (Line One)" fieldid="lineOne" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="address-2 edit-field">
				<LabelTextField isediting={ editMode } label="Address (Line Two)" fieldid="lineTwo" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="address-city edit-field">
				<LabelTextField isediting={ editMode } label="City" fieldid="city" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="address-state edit-field">
				<LabelTextField isediting={ editMode } label="State" fieldid="state" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="address-zip edit-field">
				<LabelTextField isediting={ editMode } label="Zip Code" fieldid="postalCode" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
		</div>
	);
}

