import "../../global.scss";
import './profile.scss';

import React from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from "dayjs/plugin/utc";

import { AstraIcon, PersonalInfoIcon, ContactInfoIcon, BillingIcon } from '../../img/icons';


dayjs.extend(advancedFormat);
dayjs.extend(utc);


export function Profile() {

	const navigate = useNavigate();

	return (
		<div className="profile-form">
			<div className="astra-icon">
				<AstraIcon />
			</div>
			<div className="my-account">
				<div className="section-header">My Account</div>
				<div className="personal-info" >
					<button onClick={ () => { navigate('/profile/personal-info') }}>
						<div className="button-caption">
							<div className="icon">
								<PersonalInfoIcon />
							</div>
							<div className="label">
								Personal Info
							</div>
						</div>
					</button>
				</div>
				<div className="contact-info" >
					<button onClick={ () => { navigate('/profile/contact-info') }}>
						<div className="button-caption">
							<div className="icon">
								<ContactInfoIcon />
							</div>
							<div className="label">
								Contact Info
							</div>
						</div>
					</button>
				</div>
			</div>
			<div className="account-management">
				<div className="section-header">Management</div>
				<div className="billing-management" >
					<button onClick={ () => { navigate('/profile/billing-info') }}>
						<div className="button-caption">
							<div className="icon">
								<BillingIcon />
							</div>
							<div className="label">
								Billing
							</div>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
}

