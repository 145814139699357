import "../../global.scss";
import "./login.scss";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import {
  loginUser,
  registerUser,
  FIREBASE_ERROR_MESSAGES,
} from "../../services/auth/firebase-user-auth";
import { AstraIcon } from "../../img/icons";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

function Login() {
  const navigate = useNavigate();
  const [userRegistering, setUserRegistering] = useState(false);
  const [authError, setAuthError] = useState("");
  const [userInfo, setUserInfo] = useState({
    userEMail: "",
    password: "",
    confirmPassword: "",
  });

  const handleLoginButtonClick = async (event) => {
    const loginFunc = userRegistering ? registerUser : loginUser;
    loginFunc(userInfo.userEMail, userInfo.password)
      .then((authResult) => {
        setAuthError("");
        navigate("/home");
      })
      .catch((error) => {
        setAuthError(
          FIREBASE_ERROR_MESSAGES[error.error.code] ??
            FIREBASE_ERROR_MESSAGES["unknown"] + "(" + error.error.code + ")"
        );
      });
  };

  const changeTextField = (event) => {
    let newUserInfo = { ...userInfo };
    newUserInfo[event.target.attributes.field.value] = event.target.value;

    setUserInfo(newUserInfo);
  };

  const toggleUserRegistering = (event) => {
    event.preventDefault();
    setUserRegistering(!userRegistering);
    setAuthError("");
  };

  return (
    <div className="container-fluid">
      <div id="login" className="container">
        <div className="astra-icon">
          <AstraIcon />
        </div>
        <h2 className="mb-5">Login or Signup</h2>
        <div className="content sm">
          <div className="form-col">
            <div className="textbox-container">
              <input
                type="text"
                field="userEMail"
                placeholder="Email address"
                value={userInfo.userEMail}
                onChange={changeTextField}
              />
            </div>
            <div className="textbox-container">
              <input
                field="password"
                placeholder="Password"
                value={userInfo.password}
                onChange={changeTextField}
                type="password"
              />
            </div>
            {userRegistering && (
              <div className="textbox-container">
                <input type="password" placeholder="Confirm password" />
              </div>
            )}
            {authError && (
              <p className="field-error">
                {userRegistering ? "Registration error: " : "Login error: "}
                {authError}
              </p>
            )}
            <button
              className="btn btn-primary mx-auto mt-10"
              onClick={handleLoginButtonClick}
            >
              {userRegistering ? "Register" : "Login"}
            </button>
            <button
              className="btn btn-text my-10"
              onClick={(event) => toggleUserRegistering(event)}
            >
              {userRegistering
                ? "Already have an account? Click here to login."
                : "Don't have an account? Click here to register."}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
