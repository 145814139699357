




export function BillingIcon() {
	return (
		<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M0.482942 11.071L10.7647 0.496781C11.4087 -0.165594 12.4623 -0.165594 13.1063 0.496781L18.9607 6.51769C19.6048 7.18006 19.6048 8.26357 18.9607 8.92595L8.67898 19.5001C8.02049 20.1773 7.01534 20.1573 6.3599 19.4957C4.38671 17.5045 2.4381 15.49 0.483042 13.4793C-0.161014 12.8169 -0.161014 11.7334 0.483041 11.0711L0.482942 11.071ZM5.65629 15.968L9.40657 12.1111L10.0829 12.8067L6.33265 16.6636L5.65629 15.968ZM6.79399 17.1381L12.5107 11.2588L13.1873 11.9546L7.47054 17.8339L6.79399 17.1381ZM11.4876 5.69901L13.1092 4.03127C13.3669 3.76625 13.7882 3.76605 14.0459 4.03108L15.0119 5.02453C15.2696 5.28956 15.2694 5.72287 15.0117 5.98789L13.3901 7.65563C13.1326 7.92046 12.711 7.92046 12.4535 7.65563L11.4876 6.66218C11.2301 6.39735 11.2301 5.96384 11.4876 5.69901Z" fill="#668BB4"/>
			<path d="M21.1543 14.174H21.0536V12.6463C21.0536 11.186 19.9004 10 18.4855 10C17.0656 10 15.9124 11.1859 15.9124 12.6463V14.174H15.8167C15.3484 14.174 14.9707 14.5676 14.9707 15.0441V19.1352C14.9707 19.6116 15.3484 20 15.8167 20H21.1543C21.6175 20 22.0003 19.6116 22.0003 19.1352V15.0441C22.0003 14.5676 21.6176 14.174 21.1543 14.174ZM20.0465 14.174H16.9195V12.6463C16.9195 11.7608 17.6244 11.0358 18.4855 11.0358C19.3465 11.0358 20.0465 11.7608 20.0465 12.6463L20.0465 14.174Z" fill="#668BB4"/>
		</svg>
	)
}

