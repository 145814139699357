



export function EmailIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="14"
			viewBox="0 0 20 14"
			fill="#000"
		>
			<path
				d="M1.34685 0.365603C1.75793 0.126183 2.22511 7.54793e-05 2.70076 0H17.2871C17.7628 7.37055e-05 18.23 0.126192 18.641 0.365603L11.4988 6.57604C11.0807 6.93723 10.5465 7.13602 9.99395 7.13602C9.44134 7.13602 8.90723 6.93723 8.48908 6.57604L1.34685 0.365603ZM12.5885 7.8239C11.8694 8.44462 10.951 8.78611 10.0011 8.78611C9.05123 8.78611 8.13278 8.4446 7.41368 7.8239L0.235981 1.58742C0.0771343 1.94108 -0.00336433 2.32489 0.000107722 2.71254V10.8737C0.000697404 11.5929 0.286703 12.2826 0.795301 12.7911C1.30382 13.2997 1.99346 13.5857 2.71266 13.5863H17.2873C18.0065 13.5857 18.6962 13.2997 19.2047 12.7911C19.7133 12.2826 19.9993 11.5929 19.9999 10.8737V2.71254C20.0034 2.32489 19.9229 1.94108 19.764 1.58742L12.5885 7.8239Z"
				fill="#083D4E"
			/>
		</svg>
	)
}
