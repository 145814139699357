import "../../global.scss";
import './profile.scss';

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from "dayjs/plugin/utc";

import OnboardingService, { OnboardingDataCallbackType } from "../../services/onboarding/onboarding-service";
import { LabelTextField } from '../../components/label-text-combo-field/label-text-combo-field';

import { AstraIcon, NewChevronLeft } from '../../img/icons';


dayjs.extend(advancedFormat);
dayjs.extend(utc);


export function ContactInfo() {

	const navigate = useNavigate();
	const [ editMode, setEditMode ] = useState(false);
	const [ onboardingService, setOnboardingService ] = useState(null);

	const [ userData, setUserData ] = useState({
		email: ''
		, phoneNumber: ''
		, emergencyName: ''
		, emergencyPhone: ''
	});

	useEffect(() => {
		const tOnboardingService = new OnboardingService();
		setOnboardingService(tOnboardingService);
	
		tOnboardingService.registerCallback(() => {
			const userDoc = tOnboardingService.getUserDoc();
			const newUserData = { ...userData };
			Object.keys(userData).forEach((key) => {
				newUserData[key] = userDoc[key] ?? '';
			});
			setUserData(newUserData);
		}, OnboardingDataCallbackType.USER_DOC);
	}, [ navigate ]);	// eslint-disable-line react-hooks/exhaustive-deps

	const handleFieldChange = (fieldId, value) => {
		const newUserData = { ...userData };
		newUserData[fieldId] = value;

		setUserData(newUserData);
	}

	const getFieldValue = (fieldId, fieldFormat) => {
		let fieldValue = userData[fieldId];

		return fieldValue;
	}

	const toggleEditMode = async () => {
		const newEditMode = (! editMode);
		setEditMode(newEditMode);
		if (! newEditMode) {
			onboardingService.saveStepData(userData);
			await onboardingService.saveActiveDocument();
		}
	}


	return (
		<div className="profile-form">
			<div className="astra-icon">
				<AstraIcon />
			</div>
			<div className="dialog-content">
				<div className="profile-back-button" onClick={ () => navigate('/profile') }>
					<NewChevronLeft />
				</div>
				<div className="dialog-label">
					Contact Info
				</div>
				<div className="edit-button" onClick={ toggleEditMode }>
					{ editMode ? 'Done' : 'Edit' }
				</div>
			</div>
			<div className="email edit-field">
				<LabelTextField isediting={ editMode } label="Email" fieldid="email" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="phone-number edit-field">
				<LabelTextField isediting={ editMode } label="Phone Number" fieldid="phoneNumber" fieldtype="tel" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="dialog-content">
				<div className="dialog-label">
					EMERGENCY CONTACT
				</div>
			</div>
			<div className="emergency-name edit-field">
				<LabelTextField isediting={ editMode } label="Name" fieldid="emergencyName" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
			<div className="phone-number edit-field">
				<LabelTextField isediting={ editMode } label="Phone Number" fieldid="emergencyPhone" fieldtype="tel" onChange={ handleFieldChange } getfieldvalue={ getFieldValue } />
			</div>
		</div>
	);
}

