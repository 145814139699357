
import './label-text-combo-field.scss';

import React from "react";
import { PatternFormat, patternFormatter } from 'react-number-format';


import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from "dayjs/plugin/utc";



dayjs.extend(advancedFormat);
dayjs.extend(utc);


export function LabelTextField(props) {

	const handleTextChange = (event) => {
		let newFieldValue = '';
		if (fieldType === 'tel') {
			console.log(event);
			newFieldValue = event.value;
		}
		else {
			newFieldValue = event.target.value;
		}
		props.onChange(props.fieldid, newFieldValue);
	}

	const getFieldValue = () => {
		let result = props.getfieldvalue(props.fieldid, props.format);
		if (fieldType === 'tel') {
			result = patternFormatter(result, { format: '+1 (###) ###-####' });
		}
		return result;
	}

	const fieldType = props.fieldtype ?? "text";

	return (
		<div className="label-text-field-container">
			<div className="field-label">
				{ props.label }
			</div>
			{
				props.isediting &&
				(
					fieldType === 'tel' ?
					(
						<div className="field-field">
							<PatternFormat format="+1 (###) ###-####" className="field-input" displayType="input" onValueChange={ handleTextChange } value={ props.getfieldvalue(props.fieldid, props.format) } />
						</div>
					)
					: (
						<div className="field-field">
							<input type={ fieldType } className="field-input" value={ getFieldValue() } onChange={ handleTextChange } />
						</div>
					)
				)
			}
			{ 
				(! props.isediting) &&
				<div className="field-value">
					{ getFieldValue() }
				</div>
			}
		</div>
	);
}

